import React, { Component } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import _ from "lodash";

const getTableHeader = (columnDetails, role) => {

    let tableHeading = []
    {
        columnDetails.map((item) => {
            if(role == 7 && (item.fieldName === 'Assigned To')){

            }else{
                tableHeading.push(
                <th scope="col" key={item.fieldName} className={`filter_text ${item.className}`} > {item.fieldName} </th>
                )
            }

        })
    }
    return <thead><tr> {tableHeading} </tr></thead>
}
const statusOptions = [
    { label: "Open", value: 1 },
    { label: "Closed", value: 2 },
    { label: "Pending with spare", value: 3 },
    { label: "Urgent", value: 4 },
    { label: "Non Contactable", value: 5 },
    { label: "Self Done", value: 6 },
    { label: "Cancelled", value: 7 },
    { label: "Returned", value: 8 },
    { label: "Not delivered", value: 9 },
    { label: "Un-assigned", value: 10 },
    { label: "Done", value: 11 },
    { label: "Completed", value: 12 },
    { label: "Rescheduled", value: 13 },
    { label: "Pending with reason", value: 14 },
    { label: "Installation Done & Apps pending", value: 15 },
    { label: "Repair Done", value: 16 },
    { label: "Spare Required Dispatch Pending", value: 17 },
    { label: "Spare Required", value: 18 },
    { label: "UnInstallation Done", value: 19 },
    { label: "Issue Non Resolvable", value: 20 },
    { label: "Issue Visit Done", value: 21 },
    { label: "Repair by Tech", value: 22 },
    { label: "Part Received", value: 23 },
    { label: "Installation Done", value: 24 }
];


const statusOptionsReport = [
    { label: "Closed", value: 2 },
    { label: "Pending with spare", value: 3 },
    { label: "Done", value: 11 },
    { label: "Completed", value: 12 },
    { label: "Pending with reason", value: 14 },
    { label: "Installation Done & Apps pending", value: 15 },
    { label: "Repair Done", value: 16 },
    { label: "Spare Required", value: 18 },
    { label: "UnInstallation Done", value: 19 },
    { label: "Issue Non Resolvable", value: 20 },
    { label: "Issue Visit Done", value: 21 },
    { label: "Installation Done", value: 24 },
    { label: "Spare Required Dispatch Pending", value: 17 },
];

const serviceTypeOptions = [
    { value: "", label: 'All' },
    { value: 1, label: 'Installation' },
    { value: 2, label: 'Repair' },
    { value: 3, label: 'Uninstallation' },
    { value: 4, label: 'Revisit' }

];

const productServiceTypeOptions = [
    { value: "", label: 'All' },
    { value: 1, label: 'Installation' },
    { value: 2, label: 'Repair' },
    { value: 3, label: 'Uninstallation' },
    { value: 4, label: 'Revisit' }

];

const paymentTypeOptions = [
    { value: "", label: 'All' },
    { value: 1, label: 'Free' },
    { value: 2, label: 'Prepaid' },
    { value: 3, label: 'COD' }
];

const serviceCategoryFilterOptions = [
    { value: "", label: 'All' },
    { value: 1, label: 'DOMESTIC' },
    { value: 2, label: 'COMMERCIAL' },
    { value: 3, label: 'EVENT' }
];

const statusFilterOptions = [
    { value: "", label: 'All' },
    { value: 1, label: 'OPEN' },
    { value: 2, label: 'CLOSED' },
    { value: 3, label: 'ASSIGNED' },
    { value: 4, label: 'CANCELLED' },
    { value: 5, label: 'ON-HOLD' },
    { value: 6, label: 'PENDING ASSIGNMENT' },
    { value: 7, label: 'SELF DONE' },
    { value: 8, label: 'COMMS PENDING' },
    { value: 9,label: 'DONE' },
];
const sourceFilterOptions = [
    { value: "", label: 'All' },
    { value: 0, label: 'Amazon' },
    { value: 1, label: 'Flipkart' },
    { value: 2, label: 'Amazon MFN' },
    { value: 3, label: 'Flipkart MFN' },
    { value: 4, label: 'Cult WebSite' },
    { value: 5, label: 'Cult App' },
    { value: 6, label: 'CultFit' },
    { value: 7, label: 'WebSite' },
    { value: 8, label: 'CultSport WebSite' },
    { value: 9, label: 'UrbanTerrain WebSite' },
    { value: 10, label: 'GymServ App' },
    { value: 11, label: 'SHOPIFY' }
];

const productTypeOptions = [
    { value: "Treadmill", label: 'Treadmill' },
    { value: "Elliptical", label: 'Elliptical' },
    { value: "Urban Terrain", label: 'Urban Terrain' },
    { value: "Exercise Bike", label: 'Exercise Bike ' }

];
const skillTypeOption = [
    { value: "Installation", label: 'Installation' },
    { value: "Repair", label: 'Repair' },
    { value: "Dis-assemble", label: 'Dis-assemble' }]

const genderOption = [
    { value: "0", label: 'Male' },
    { value: "1", label: 'Female' }

];
const TatOption = [
    { value: 0, label: 'All' },
    { value: 1, label: 'In Tat' },
    { value: 2, label: 'Out Tat' }

]
const getValue = (type, value) => {
    let toReturn = "";
    switch (type) {
        case "string":
            toReturn = value ? value : "";
            break;
        case "number":
            toReturn = value ? Number(value) : 0;
            break;
        case "date":
            toReturn = value ? moment(value).format('Do MMM YY') : "-";
            break;
        case "dateTime":
            toReturn = value ? moment(value).format('Do MMM YY, h:mm:ss a') : "-";
            break;
        case "dateInMil":
            toReturn = value ? moment(value.toString()).format('Do MMM YY') : "-";
            break;
        default:
            toReturn = value ? value : "";
            break;
    }
    return toReturn;
}

const getSortingIcon = (value, type) => {
    let toReturn = "fa fa-sort ml-1";
    if (type === "Number") {
        switch (value) {
            case "-1":
            case -1:
                toReturn = "fa fa-sort-numeric-desc"
                break;
            case "1":
            case 1:
                toReturn = "fa fa-sort-numeric-asc"
                break;
        }
    }
    if (type === "Text") {
        switch (value) {
            case "-1":
            case -1:
                toReturn = "fa fa-sort-alpha-desc"

                break;
            case "1":
            case 1:
                toReturn = "fa fa-sort-alpha-asc"

                break;
        }

    }

    return toReturn;

}
const validEmail = (value) => {
    var reg = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+\.([A-Za-z]{2,4})$/i;
    return reg.test(value);
}


const validCountryCode = (value) => {
    if(value === 91 || value === '91'){
        return true
    }else{
        return false
    }
 }

const validPhoneNumber = (value) => {
    var reg = /^([0-9\(\)\/\+ \-]*)$/;
    return reg.test(value);
}

const validString = (value) => {
    var reg = /^[a-z ,.'-]+$/i;
    return reg.test(value);
}

function removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
        value = originalArray[i][objKey];

        if (values.indexOf(value) === -1) {
            trimmedArray.push(originalArray[i]);
            values.push(value);
        }
    }
    return trimmedArray;
}
function handleChangeStatus(id, status, callerMethod, scope, callBackMethod) {
    let obj = {
        dieticianId: id,
        dieticianStatus: status === 1 ? 2 : 1
    }
    //Same Api being used to Deactivate/Activate  Sub Admins Or  CRM Agent
    callerMethod(obj)
        .then((res) => {
            let resp = res.data
            if (resp.statusCode === 1) {
                toast.success(resp.responseData.message)
                if (!callBackMethod) {
                    scope.getAllAdmins();
                }
                else {
                    callBackMethod();
                }

            }
        })
}


function checkIfChargeDiffNotGreaterThan110(additionCharges , additionalChargesByAdmin) {
    if (additionCharges === 0) {
        return false
    }
    if (additionCharges <= additionalChargesByAdmin) {
        return true
    } else {
        let percentage = (additionCharges - additionalChargesByAdmin) * 100 / additionalChargesByAdmin
        if (percentage <= 10) {
            return true
        } else {
            return false
        }
    }
    return false
}

function isAutoAccept(details) {
    const additionalCharges = details["additionalCharges"]?(() => {
        const x = details["additionalCharges"]["addiChargesForSpareParts"];
        const y = details["additionalCharges"]["addiChargesForTransport"];
        const z = details["additionalCharges"]["addiChargesForCourier"];
        return x + y + z;
    })() : null

    const additionalChargesByAdmin = details["additionalChargesByAdmin"] ? (() => {
        const x = details["additionalChargesByAdmin"]["addiChargesForSpareParts"];
        const y = details["additionalChargesByAdmin"]["addiChargesForTransport"];
        const z = details["additionalChargesByAdmin"]["addiChargesForCourier"];
        return x + y + z
    })() : null

    const totalAdditionalCharges = additionalCharges ? (additionalCharges.addiChargesForTransport
        + additionalCharges.addiChargesForCourier + additionalCharges.addiChargesForSpareParts) : null;

    const totalAdditionalChargesByAdmin = additionalChargesByAdmin ? (additionalChargesByAdmin.addiChargesForTransport
        + additionalChargesByAdmin.addiChargesForCourier + additionalChargesByAdmin.addiChargesForSpareParts) : null;

    if (_.isNil(totalAdditionalCharges) && _.isNil(totalAdditionalChargesByAdmin)){
        return true;
    } else if (totalAdditionalCharges == 0 && totalAdditionalChargesByAdmin == 0) {
        return true;
    } else if (!_.isNil(totalAdditionalCharges) && !_.isNil(totalAdditionalChargesByAdmin) && totalAdditionalCharges !== 0){
        if (totalAdditionalCharges <= totalAdditionalChargesByAdmin){
            return true;
        } else if (checkIfChargeDiffNotGreaterThan110(totalAdditionalCharges,totalAdditionalChargesByAdmin)){
            return true;
        } else {
            return false;
        }
    }
    return false;
}

export {
    getTableHeader,
    statusOptions,
    getValue,
    serviceTypeOptions,
    productServiceTypeOptions,
    sourceFilterOptions,
    statusFilterOptions,
    getSortingIcon,
    validEmail,
    validPhoneNumber,
    validString,
    validCountryCode,
    productTypeOptions,
    skillTypeOption,
    genderOption,
    removeDuplicates,
    TatOption,
    handleChangeStatus,
    serviceCategoryFilterOptions,
    paymentTypeOptions,
    statusOptionsReport,
    checkIfChargeDiffNotGreaterThan110,
    isAutoAccept
}

